exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categorias-js": () => import("./../../../src/pages/categorias.js" /* webpackChunkName: "component---src-pages-categorias-js" */),
  "component---src-pages-entrar-js": () => import("./../../../src/pages/entrar.js" /* webpackChunkName: "component---src-pages-entrar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-minha-area-index-js": () => import("./../../../src/pages/minha-area/index.js" /* webpackChunkName: "component---src-pages-minha-area-index-js" */),
  "component---src-pages-minha-area-nova-receita-js": () => import("./../../../src/pages/minha-area/nova-receita.js" /* webpackChunkName: "component---src-pages-minha-area-nova-receita-js" */),
  "component---src-pages-minha-area-receita-editar-[id]-js": () => import("./../../../src/pages/minha-area/receita/editar/[id].js" /* webpackChunkName: "component---src-pages-minha-area-receita-editar-[id]-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-termos-js": () => import("./../../../src/pages/termos.js" /* webpackChunkName: "component---src-pages-termos-js" */),
  "component---src-pages-usuario-[uuid]-js": () => import("./../../../src/pages/usuario/[uuid].js" /* webpackChunkName: "component---src-pages-usuario-[uuid]-js" */),
  "component---src-templates-all-recipes-template-js": () => import("./../../../src/templates/all-recipes-template.js" /* webpackChunkName: "component---src-templates-all-recipes-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-recipe-post-template-js": () => import("./../../../src/templates/recipe-post-template.js" /* webpackChunkName: "component---src-templates-recipe-post-template-js" */),
  "component---src-templates-search-template-js": () => import("./../../../src/templates/search-template.js" /* webpackChunkName: "component---src-templates-search-template-js" */)
}

