import * as React from "react";
import { ALERT_TYPE, alertsStore } from "../../external-stores/alert-store";
import CloseSvg from "../../images/close.svg";

const Alert = (props) => {
  const [colors, setColors] = React.useState({
    bg: "",
    text: "",
    svg: "",
  });

  React.useEffect(() => {
    let currentColors = {};

    switch (props.type) {
      case ALERT_TYPE.success:
        currentColors = {
          bg: "bg-green-100/75",
          text: "text-green-700",
          svg: "rgb(21 128 61)",
          border: "border-green-700",
        };
        break;
      case ALERT_TYPE.warning:
        currentColors = {
          bg: "bg-orange-100/75",
          text: "text-orange-700",
          svg: "rgb(194 65 12)",
          border: "border-orange-700",
        };
        break;
      case ALERT_TYPE.error:
        currentColors = {
          bg: "bg-red-100/75",
          text: "text-red-700",
          svg: "rgb(185 28 28)",
          border: "border-red-700",
        };
        break;
      default:
        currentColors = {
          bg: "bg-orange-100/75",
          text: "text-orange-700",
          svg: "rgb(194 65 12)",
          border: "border-orange-700",
        };
        break;
    }

    setColors(currentColors);
  }, [props.type]);

  return (
    <div>
      <div
        className={`p-4 ${colors.bg} border-l-4 ${colors.border} ${colors.text} w-full flex items-center`}
        role="alert"
      >
        <div className="w-4/5">
          <p className="font-bold">{props.title}</p>
          {props.description !== "" && <p>{props.description}</p>}
          {props.descriptionList?.map((description) => (
            <p>- {description}</p>
          ))}
        </div>

        <div className="w-1/4 flex items-center justify-end">
          <button
            aria-label="Remover alerta"
            className="cursor-pointer"
            onClick={() => alertsStore.removeAlert(props.id)}
          >
            <CloseSvg className="w-7 h-7" fill={colors.svg} />
          </button>
        </div>
      </div>
    </div>
  );
};

const Alerts = (props) => {
  const [alerts, setAlerts] = React.useState([]);

  React.useEffect(() => {
    const onAlert = (event) => {
      if (event.detail?.alerts) {
        setAlerts(event.detail.alerts);
      }
    };

    window.addEventListener("alert", onAlert);

    return () => {
      window.removeEventListener("alert", onAlert);
    };
  });

  return (
    <section className="fixed top-0 left-0 w-full z-50">
      {alerts.map((alert, index) => {
        return (
          <Alert
            key={index}
            id={alert.id}
            title={alert.title}
            description={alert.description}
            type={alert.type}
            descriptionList={alert.descriptionList}
          />
        );
      })}
    </section>
  );
};

export default Alerts;
