let nextId = 0;
let alerts = [];

export const ALERT_TYPE = {
  success: "success",
  warning: "warning",
  error: "error",
};

const addAlert = (title, description, type, descriptionList = []) => {
  const id = nextId++;
  alerts = [...alerts, { id, title, description, type, descriptionList }];
  emitChange();
};

export const alertsStore = {
  addSuccess(title, description, descriptionList = []) {
    addAlert(title, description, ALERT_TYPE.success, descriptionList);
  },
  addWarning(title, description, descriptionList = []) {
    addAlert(title, description, ALERT_TYPE.warning, descriptionList);
  },
  addError(title, description, descriptionList = []) {
    addAlert(title, description, ALERT_TYPE.error, descriptionList);
  },
  removeAlert(id) {
    alerts = alerts.filter((alert) => {
      if (alert.id === id) {
        return false;
      }

      return true;
    });

    emitChange();
  },
  clearAll() {
    alerts = [];
    emitChange();
  },
};

function emitChange() {
  const event = new CustomEvent("alert", { detail: { alerts } });
  window.dispatchEvent(event);
}
