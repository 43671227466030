import * as React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./src/styles/global.css";
import Alerts from "./src/components/alerts/alerts";

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleOAuthProvider clientId="312121303479-t7ia1t4nd4j3v326u3l4fd7i3ij4hhfq.apps.googleusercontent.com">
      <Alerts />
      {element}
    </GoogleOAuthProvider>
  );
};
